<template>
  <div class="">
    <div class="">
      <section class="content-header welcomesc pt-s-5">
        <custom-alert v-if="displayMissingMemFields==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
        <div :style="{ backgroundImage: `url(${this.dashboardWelcomeContent.image!=undefined?this.basePath+this.dashboardWelcomeContent.image:subdomain=='waterski'?'':'/dist/img/profile/banner.png'})` }" class="renewalsection">
          <div class="row">
            <div class="col-md-2  col-xs-2 col-sm-2 m-dash-logo" v-if="this.getSiteProps('member.logo') == undefined">
              <img
                  :src="'/' + this.getSiteProps('login.logo')"
                  v-if="'/' + this.getSiteProps('login.logo')"
              />
            </div>
            <div class="col-md-2  col-xs-2 col-sm-2 m-dash-logo" v-else>
              <img
                  :src="'/' + this.getSiteProps('member.logo')"
                  v-if="'/' + this.getSiteProps('member.logo')"
              />
            </div>
            <div class="col-md-7">
              <h3 class="renew-title">{{dashboardWelcomeContent.title}}</h3>
              <span class="renew-subtitle"
              >{{dashboardWelcomeContent.content}}</span
              >
            </div>
            <div class="col-md-3">
              <div class="right" style="text-align: center; margin-bottom: 10px; padding: 8px;" v-show="digitalInfoView" v-if="(profileData.info.membership && profileData.info.membership.period != 999) && profileData.info.personal_details.relation == 0 && profileData.info.membership.auto_renew">
                <div :class="subdomain=='waterski'? 'autorenewal dashboardrenewal':'autorenewal'">
                  <AutoRenewalButton :page="page" :profileData="profileData" :autoRenew="autoRenew" :autoRenewData="autoRenewData" @loadMembersInfo="loadMembersInfo" />
                </div>
              </div>
              <!-- ChildCompon -->
              <button
                  type="button"
                  class="btn btn-primary btn-renew"
                  @click="renewMemberShip"
                  v-if="(profileData.info.canUpgrade || membershipExpiring)"
              >
                <!--                v-if="profileData.info.membership_expiring"-->
                <i class="fas fa-sync-alt"></i> {{ renewalUpgradeText }}
              </button>
            </div>
          </div>
        </div>
      </section>

      <div class="video-wrapper admindash-widget">
        <custom-alert
            v-if="displayAlert == true"
            :message="alertMessage"
            v-on:showedAlert="resetAlert"
        ></custom-alert>
        <div class="row mb-3">
          <div class="col-md-3 col-sm-6 col-12 r-pad l-pad">
            <div class="info-box bg-gradient-info shadow-sm">
              <span class="info-box-icon"><i class="fas fa-user"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Membership Number </span>
                <span class="info-box-number">{{
                    profileData.info.confirmation_code
                  }}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>


          <div class="col-md-3 col-sm-6 col-12 r-pad l-pad ">
            <div class="info-box bg-gradient-success shadow-sm">
              <span class="info-box-icon"
              ><i class="far fa-address-card"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Member Type</span>
                <span class="info-box-number">{{
                    profileData.info.membership.name
                  }}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>

          <div class="col-md-3 col-sm-6 col-12 r-pad l-pad " >
            <div class="info-box bg-gradient-warning shadow-sm">
              <span class="info-box-icon"
              ><i class="far fa-calendar-alt"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Expiration Date </span>
                <span class="info-box-number">{{
                    profileData.info.membership.period != 999 ? usdate(profileData.info.membership_endtate) : 'No Expiration'
                  }}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>

          <div class="col-md-3 col-sm-6 col-12 r-pad l-pad">
            <div class="info-box bg-gradient-gray shadow-sm">
              <span class="info-box-icon"
              ><i class="fas fa-clipboard-check"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Membership Status
                  <template v-if="profileData.info.membership.id !=13 && profileData.info.membership.member_type_id !=5" ><div class="d-inline ml-2" id="tooltip" v-if="subdomain == 'waterski'"><i class="fas fa-info-circle"></i></div><b-tooltip target="tooltip" class="white">{{profileData.info.membership_status_info}} <br> Click here to review all Membership Statuses - <a href="https://www.teamusa.org/usa-water-ski/membership-status-types" target="_blank">https://www.teamusa.org/usa-water-ski/membership-status-types</a></b-tooltip></template></span>
                <span class="info-box-number float-left mr-1">{{ profileData.info.membership_status_text }}</span>
                <b-badge variant="secondary" class="ml-1 mt-1 float-left" v-if="this.profileData.info.membership_status === 5
                  && this.profileData.info.personal.relation < 2
                  && this.profileData.info.membership.name !== 'Supporting'">
                  <b-link v-b-modal.signSpouseWaiver class="text-white" >Sign now</b-link>
                </b-badge>
                <div class="btn-primary btn trnow"  v-if="this.profileData.info.membership_status == 4 ">
                  <router-link variant="secondary"  class="text-white" to="/member/safesports">Complete Training Now</router-link>
                </div>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>
        </div>
        <div class="row memberdashboard-widgets shopnow image">
          <div class="col-md-6 r-pad l-pad">
            <div class="position-relative" v-if="dashboardBannerStatus">
              <a :href="linkToPage" target="_blank">
              <img class="img-fluid" :src="basePath+dashboardBannerContent.image" v-if="dashboardBannerContent.image"> </a>
              <div class="overlaycstext imageContent dashboardimage card rounded-0">
                  <div class="text-dark mb-0"  v-html="dashboardBannerContent.content">
                  </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 l-pad pr-0">
            <div class="row">
              <div class="col-md-6 r-pad l-pad mb-1 pl-0" :class="profileData.info.membership.id == 13 || profileData.info.membership.member_type_id == 5 || profileData.info.membership.member_type_id == 7? 'gray-out-link' : ''" v-if="subdomain == 'waterski'&&profileData.info.membership.member_type_id != 7">
                <div
                    class="border-wrapper  shadow-sm bg_blue bg-white"
                    :class="
                    profileData.info.safe_sport.safe_sport_status == 0
                      ? 'border-danger'
                      : 'border-green'
                  "
                >
                  <div class="border-text mb-1">
                    <h4> Safe Sport Status</h4>
                    <span v-if="safeSportApiCall == false">{{ safeSportRefreshButton }}</span>
                    <span v-else>
                      {{ profileData.info.safe_sport.safe_sport_status_text || "N/A" }}
                      <template v-if="profileData.info.safe_sport.safe_sport_status == 13 && profileData.info.safe_sport.expiration">
                        (On {{ profileData.info.safe_sport.expiration }})
                      </template>
                      <template v-if="profileData.info.safe_sport.safe_sport_status == 3 && profileData.info.safe_sport.expiration">
                        (Expires {{ profileData.info.safe_sport.expiration }})
                      </template>
                    </span>
                  </div>
                  <div v-if="profileData.info.safe_sport.safe_sport_status == 0" class="border-icon">
                    <span class="info-box-icon"
                    ><i class="far fa-times-circle"></i
                    ></span>
                  </div>
                  <div v-if="profileData.info.safe_sport.safe_sport_status == 1" class="border-icon">
                    <span class="info-box-icon"
                    ><i class="far fa-clock text-warning"></i></span>
                  </div>
                  <div v-if="profileData.info.safe_sport.safe_sport_status == 3" class="border-icon">
                    <span class="info-box-icon"
                    ><i class="far fa-check-circle"></i></span>
                  </div>
                  <div v-if="profileData.info.safe_sport.safe_sport_status == 13" class="border-icon">
                    <span class="info-box-icon"
                    ><i class="far fa-calendar-times text-danger"></i></span>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6 r-pad l-pad mb-1" v-if="subdomain != 'shooting'">
                <div class="border-wrapper border-yellow shadow-sm">
                  <div class="border-text">
                    <h4>Background Screen Status</h4>
                    <span class=""> Pending </span>
                  </div>
                  <div class="border-icon">
                    <span class="info-box-icon"
                      ><i class="fas fa-sync-alt"></i
                    ></span>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6 r-pad l-pad mb-1" :class="profileData.info.membership.id == 13 || profileData.info.membership.member_type_id == 5 ? 'gray-out-link' : ''" v-if="subdomain == 'waterski'&&profileData.info.membership.member_type_id != 7">
                <div  v-if="ignoreWaiverMembership.includes(profileData.info.membership.id)" class="border-wrapper  shadow-sm bg_blue bg-white">
                  <div class="border-text mb-1">
                    <h4>Waiver Status</h4>
                    <span>N/A</span>
                  </div>
                </div>
                <div  v-else-if="profileData.info.waiver_status ==1" class="border-wrapper  shadow-sm bg_blue bg-white">
                  <div class="border-text mb-1">
                    <h4>Waiver Status</h4>
                    <span class="text-success">Signed</span>
                  </div>
                  <div class="border-icon">
                    <template>
                      <div class="" id="tooltip1"><i class="fas fa-info-circle"></i></div>
                      <b-tooltip target="tooltip1" class="white">
                        <span v-if="profileData.info.waiver_status ==1"> Signed</span>
                        <span v-if="profileData.info.waiver_status ==0"> Not Signed</span>
                      </b-tooltip>
                    </template>
                  </div>
                </div>
                <div v-else-if="profileData.info.waiver_status == 0" class="border-wrapper  shadow-sm bg_blue bg-white">
                  <div class="border-text mb-1">
                    <h4>Waiver Status</h4>
                    <span class="text-danger"> Not Signed</span>
                  </div>
                  <div class="border-icon">
                    <template>
                      <div class="" id="tooltip2"><i class="fas fa-info-circle"></i></div>
                      <b-tooltip target="tooltip2" class="white">
                        <span v-if="profileData.info.waiver_status ==1"> Signed</span>
                        <span v-if="profileData.info.waiver_status ==0"> Not Signed</span>
                      </b-tooltip>
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="subdomain == 'shooting'" class="mdashicon col-md-12 r-pad pl-0">
                <div class=" bg-ins_prog2 mb-2 shadow-sm">
                  <div class="card-header rm-border">
                    <i class="fas fa-question text-dark"></i>
                    <h3 class="text-dark"> Are you interested in hosting a USAS-sanctioned match? Reach out to <a href="mailto:competitions@usashooting.org">competitions@usashooting.org</a> to request individualized Match Director access to the Events System or ask any questions about the process.</h3>
                  </div>
                </div>
              </div>
              <div v-if="subdomain == 'shooting'" class="col-md-12 r-pad pl-0">
                <a target="_blank" class="" href="https://ems.usashooting.org/event_list">
                  <div class=" bg-ins_prog2 mb-2 shadow-sm">
                    <div class="card-header rm-border">
                      <i class="fas fa-crosshairs text-dark"></i>
                      <h3 class="text-dark">Find out about upcoming USA Shooting events.</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div v-if="subdomain == 'shooting'" class="mdashicon col-md-6 pl-0">
                <a target="_blank" class="" href="https://store.usashooting.org/">
                  <div class="bg-ins_prog1 mb-2 shadow-sm" style="background: #fff;">
                    <div class="card-header rm-border">
                      <i class="fas fa-shopping-cart text-dark"></i>
                      <h3 class="text-dark">Merchandise</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div v-if="subdomain == 'shooting'" class="mdashicon col-md-6 r-pad l-pad">
                <a target="_blank" class="" href="https://www.usashooting.org/donate-20141126072420/donate-20200716163507">
                  <div class="bg-ins_prog1 mb-2 shadow-sm" style="background: #fff;">
                    <div class="card-header rm-border">
                      <i class="fas fa-donate text-dark"></i>
                      <h3 class="text-dark"> Donation</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div v-if="getSiteProps('member.banner_visible')" class="col-md-12 pl-0 l-pad newsandannouncement">
                <!-- <div class="card-header">
                  <h3 class="card-title">News & Announcements</h3>
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                </div> -->
                <!-- /.card-header -->
                <!-- <div class="card-body p-0 news_annouce">
                 <a target="_blank" href="https://www.usashooting.org/news" class="uppercase"><img style="width:100%;" class="img-fluid" src="/shooting/dashboard-news.jpg"></a>
                 <span class="text-white newsannouncement">News &amp; Announcements</span> -->
                <!-- <ul class="products-list product-list-in-card pl-2 pr-2">
                  <li class="item pt-3 pb-3">
                    <div class="product-img">
                      <img :src="'/' + this.getSiteProps('login.logo')" alt="Product Image" class="img-size-50">
                    </div>
                    <div class="product-info pl-2">
                      <a target="_blank" href="https://www.usashooting.org/news/2021/8/11/1389-12-usa-shooting-athletes-receive-prestigious-issf-presidents-cup-invites" class="product-title text-dark">
                      <span class="">
                         USA Shooting Athletes Receive Prestigious ISSF President's Cup Invites
                      </span><br>
                      <span class="text-primary">08/11/2021</span>
                      </a>
                    </div>
                  </li>

                  <li class="item pt-3 pb-3">
                    <div class="product-img">
                      <img :src="'/' + this.getSiteProps('login.logo')" alt="Product Image" class="img-size-50">
                    </div>
                    <div class="product-info pl-2">
                      <a target="_blank" href="https://www.usashooting.org/news/2021/8/3/1387-six-olympic-medals-for-usa-shooting-best-performance-since-1964-" class="product-title text-dark">
                      <span class="">
                          Six Olympic Medals for USA Shooting, Best Performance Since 1964
                      </span><br>
                      <span class="text-primary">08/03/2021</span>
                      </a>
                    </div>
                  </li>
                </ul> -->
                <!-- </div> -->
                <div class="col-md-12 pl-0">
                  <div class="itm">
                    <div class="nft_pic">
                      <a target="_blank" :href="this.getSiteProps('member.banner_link')">
                  <span>
                    <span class="nft_pic_info">
                      <div style="width:260px;">
                        <span class="nft_pic_title">{{this.getSiteProps('member.dashboard_title_text')}}</span>
                        <span class="nft_pic_by mt-2 mb-2">{{this.getSiteProps('member.dashboard_content_text')}}</span>
                        <span class="clk_nft">Click Here to Apply!</span>
                        </div>
                    </span>
                  </span>
                        <div class="nft_pic_wrap">
                          <img :src="this.getSiteProps('member.dashboard_banner')" class="lazy img-fluid" alt="">
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- <div class="card-footer bgevent text-center">
                  <a target="_blank" href="https://www.usashooting.org/news" class="uppercase"><img class="img-fluid" :src="this.getSiteProps('member.adbanner')" v-if="this.getSiteProps('member.adbanner')"></a>
                </div> -->
                <!-- /.card-footer -->
              </div>

              <div class="col-md-6 r-pad pl-0 mt-2 " :class="{'gray-out-link': enableMagazine }" v-if="this.prop_dashboardLinks.magazine == true">
                <div class="db-link">
                <router-link class="" to="/member/water-skier-magazine">
                  <div class=" bg-ins_prog2 mb-2 shadow-sm">
                    <div class="card-header rm-border ssprts">
                      <i class="fas fa-book-reader text-dark"></i>
                      <h3 class="text-dark">Water Skier Magazine</h3>
                    </div>
                  </div>
                </router-link>
                </div>
              </div>
              <div class="col-md-6 r-pad l-pad mt-2" v-if="this.prop_dashboardLinks.makedonation == true">
                <router-link class="" to="/member/member-donation">
                  <div class="bg-ins_prog1 mb-2 shadow-sm">
                    <div class="card-header rm-border ssprts">
                      <i class="fas fa-donate text-dark"></i>
                      <h3 class="text-dark">Make a Donation</h3>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-6 pl-0 r-pad l-pad " :class="profileData.info.membership.id == 13 || profileData.info.membership.member_type_id == 5 || profileData.info.membership.member_type_id == 7? 'gray-out-link' : ''" v-if="this.prop_dashboardLinks.instructorcoach == true&&profileData.info.membership.member_type_id != 7">
                <div class="db-link">
                  <router-link class="" to="/member/online-testing">
                    <div class="bg-ins_prog mb-2 shadow-sm">
                      <div class="card-header rm-border ssprts">
                        <i class="fas fa-chalkboard-teacher text-dark"></i>
                        <h3 class="text-dark">Instructor/Coach Programs</h3>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 r-pad l-pad" :class="profileData.info.membership.id == 13 || profileData.info.membership.member_type_id == 5 || profileData.info.membership.member_type_id == 7 ? 'gray-out-link' : ''" v-if="this.prop_dashboardLinks.driverprogram == true&&profileData.info.membership.member_type_id != 7">
                <div class="db-link">
                  <router-link class="" to="/member/drivers-program">
                    <div class="bg-ins_prog1 mb-2 shadow-sm">
                      <div class="card-header rm-border ssprts">
                        <i class="fas fa-dharmachakra text-dark"></i>
                        <h3 class="text-dark">Driver Programs / MVR Review</h3>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 p-sm-0 r-pad l-pad " :class="profileData.info.membership.id == 13 || profileData.info.membership.member_type_id == 5 || profileData.info.membership.member_type_id == 7 ? 'gray-out-link' : ''" v-if="this.prop_dashboardLinks.safesports == true&&profileData.info.membership.member_type_id != 7">
                <div class="db-link">
                  <router-link class="" to="/member/safesports">
                    <div class="bg-ins_prog1 mb-2 shadow-sm">
                      <div class="card-header rm-border ssprts">
                        <i class="fas fa-user-shield text-dark"></i>
                        <h3 class="text-dark">Safe Sport Training / Background Screening</h3>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 r-pad l-pad" :class="profileData.info.membership.id ==13 || profileData.info.membership.member_type_id == 5 || profileData.info.membership.member_type_id == 7 ? 'gray-out-link' : ''" v-if="this.prop_dashboardLinks.myofficials == true&&profileData.info.membership.member_type_id != 7">
                <div class="db-link">
                  <router-link
                      class=""
                      to="/member/my-officials-ratings-certifications"
                  >
                    <div class="bg-ins_prog1 mb-2  shadow-sm">
                      <div class="card-header rm-border ssprts">
                        <i class="fas fa-star-half-alt text-dark"></i>
                        <h3 class="text-dark">
                          My Official Ratings / Certifications
                        </h3>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 r-pad pl-0" v-if="this.prop_dashboardLinks.membershidiscount == true">
                <a
                    href="https://usawaterski.perkspot.com/login/!2f!3futm_medium!3demail!26utm_id!3dwelcome!26promotionId!3d"
                    target="_blank"
                >
                  <div class=" bg-ins_prog2 mb-0">
                    <div class="card-header rm-border ssprts ">
                      <i class="fas fa-tag text-dark"></i>
                      <h3 class="text-dark">Membership Discounts</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 r-pad l-pad" v-if="this.prop_dashboardLinks.merchandise == true">
                <a href="https://shop.usawaterski.org/" target="_blank">
                  <div class=" bg-ins_prog2 mb-0">
                    <div class="card-header rm-border ssprts">
                      <i class="fas fa-shopping-cart text-dark"></i>
                      <h3 class="text-dark">USA-WSWS Merchandise</h3>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>



          <div id="waiver_status_box_section" class="col-md-12 profile-body r-pad l-pad waiver-status_box" v-if="[2,5,7,13].indexOf(profileData.info.membership.member_type_id) === -1">
            <div class="card" :class="{marginminustop: subdomain == 'shooting'}">
              <div class="card-header p-0">
                <div class="header-title">
                  <div class="mem-header mt-2 mb-2">
                    <h4 style="font-size: 20px !important;">Waiver Status Box</h4>
                  </div>
                </div>

                <div
                    class="modal-btn text-right mt-3 mb-2"
                    v-if="
                    this.profileData.info.waiver_status === 0
                    && this.profileData.info.personal.relation < 2
                    && this.profileData.info.membership.name !== 'Supporting'
                  "
                >
                  <!-- <b-button

                    v-b-modal.signSpouseWaiver
                    variant="primary"
                    style="width: 200px;"
                  >
                    <i class="fas fa-pencil-alt"></i> Sign Your Waiver
                  </b-button> -->
                  <button v-b-modal.signSpouseWaiver
                          variant="primary" class="btn btn-outline-primary savebtn p-2"><i class="fas fa-pencil-alt"></i> Sign Your Waiver</button>
                </div>
                <div class="modal-btn text-right mt-3 mb-2" v-if="this.profileData.info.need_child_waiver_sign">
                  <button v-b-modal.signChildWaiver variant="primary" class="btn btn-outline-primary savebtn p-2">
                    <i class="fas fa-pencil-alt"></i> Sign Your Children Waiver
                  </button>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <b-table
                    id="my-table"
                    :items="profileData.info.waiver"
                    :fields="tblWaiverFields"
                    hover
                    show-empty
                >
                  <template :fields="items">
                    <div class="text-left py-0 align-middle">
                      <div class="btn-group btn-group-sm">
                        <button class="btn btn-info">
                          <i class="fas fa-eye"></i>
                        </button>
                        <button class="btn btn-primary ml-1">
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button class="btn btn-danger ml-1">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </template>
                  <template #empty="scope">
                    <h4>{{ scope.emptyText }}</h4>
                  </template>
                  <template #cell(waivers_id)="row">
                    {{ getWaiverName(row.item.waivers_id) }}
                  </template>
                  <template #cell(initials)="row">
                    {{ row.item.initials != "" ? "Signed" : "Not Signed" }}
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3 mb-3 " v-if="subdomain == 'waterski' && [5,7,13].indexOf(profileData.info.membership.member_type_id) === -1">
            <div v-if="getError" class="alert alert-danger">
              Error Occurred while fetching the information; please contact administration.
            </div>
            <b-card body-class=" pl-0 pt-0 pr-0 table-responsive">
              <b-card-header style="display: block;">
                <div class="profile-body">
                  <div class="mem-header">
                    <div class="header-title">
                      <h4>Official Ratings</h4>
                    </div>
                  </div>
                </div>
                <button v-if="displayOfficialRatings" @click="downloadOfficials('ratings',profileData.info.id)" class="btn btn-download pull-right">
                  <i class="fa fa-circle-notch fa-1x fa-spin" v-if="downloading.ratings"></i>
                  <img src="/dist/img/profile/ico-download.png" v-else>
                </button>
              </b-card-header>
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <b-table
                  id="my-table"
                  :items="ratingData"
                  :fields="ratingTblFields"
                  hover
                  show-empty
                  :sort-by.sync="ratingSortBy"
                  :sort-desc.sync="ratingSortDesc"
                  v-if="displayOfficialRatings"
              >
                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                </template>
                <template #cell(division)="row">
                  {{ row.item.Rating.split(": ")[2] }}
                </template>
                <template #cell(rating_type)="row">
                  {{ row.item.Rating.split(": ")[0] }}
                </template>
                <template #cell(level)="row">
                  {{ row.item.Rating.split(": ")[1] }}
                </template>
                <template #cell(EventAbbreviation)="row">
                  {{ eventName[row.value] }}
                </template>
                <template #cell(ExpirationDate)="row">
                  <span v-html="row.value"></span>
                </template>
                <template #cell(Active)="row">
                  <span>{{ row.value==1?"No":"Yes" }}</span>
                </template>
                <template #cell(details)="{ item }">
                  <a href="javascript:void(0);" @click="viewOfficialDetails(item)">Details</a>
                </template>
              </b-table>
              <span v-if="!displayOfficialRatings" class="col-12">Currently, We are working. Please check later</span>
            </b-card>
          </div>
          <div class="col-md-12 mt-3 mb-3 " v-if="subdomain == 'waterski' && [5,7,13].indexOf(profileData.info.membership.member_type_id) === -1">
            <b-card body-class="pl-0 pt-0 pr-0 table-responsive">
              <b-card-header style="display: block;">
                <div class="profile-body">
                  <div class="mem-header">
                    <div class="header-title">
                      <h4>Certifications</h4>
                    </div>
                  </div>
                </div>
                <button v-if="displayOfficialRatings" @click="downloadOfficials('certs',profileData.info.id)" class="btn btn-download pull-right">
                  <i class="fa fa-circle-notch fa-1x fa-spin" v-if="downloading.certs"></i>
                  <img src="/dist/img/profile/ico-download.png" v-else>
                </button>
              </b-card-header>
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <b-table
                  id="my-table"
                  :items="certsData"
                  :fields="certsTblFields"
                  hover
                  show-empty
                  :sort-by.sync="certsSortBy"
                  :sort-desc.sync="certsSortDesc"
                  v-if="displayOfficialRatings"
              >
                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                </template>
              </b-table>
              <span v-if="!displayOfficialRatings" class="col-12">Currently, We are working. Please check later</span>
            </b-card>
          </div>

          <div class="col-md-12 mt-3 mb-3" v-if="subdomain == 'waterski' && [5,7,13].indexOf(profileData.info.membership.member_type_id) === -1">
            <b-card body-class="pl-0 pt-0 pr-0 table-responsive">
              <b-card-header style="display: block;">
                <div class="profile-body">
                  <div class="mem-header">
                    <div class="header-title">
                      <h4>Events</h4>
                    </div>
                  </div>
                </div>
                <button v-if="displayOfficialRatings" @click="downloadOfficials('events',profileData.info.id)" class="btn btn-download pull-right">
                  <i class="fa fa-circle-notch fa-1x fa-spin" v-if="downloading.events"></i>
                  <img src="/dist/img/profile/ico-download.png" v-else>
                </button>
              </b-card-header>
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <b-table
                  id="events-table"
                  :items="eventsData"
                  :fields="eventsTblFields"
                  hover
                  show-empty
                  :per-page="eventsPerPage"
                  :current-page="eventsCurrentPage"
                  v-if="displayOfficialRatings"
              >
                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                </template>
                <template #cell(ParticpationDate)="row">
                  <span v-html="formatDateTime(row.value)"></span>
                </template>
              </b-table>
              <span v-if="!displayOfficialRatings" class="col-12">Currently, We are working. Please check later</span>
              <div class="row mt-4" v-if="displayOfficialRatings">
                <div class="col-md-3 mx-auto">
                  <div class="overflow-auto">
                    <b-pagination
                        v-model="eventsCurrentPage"
                        :total-rows="eventsData.length"
                        :per-page="eventsPerPage"
                        size="sm"
                        align="fill"
                        prev-text="Previous"
                        next-text="Next"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <partner-season  @partner-clicked="handlePartnerClicked"></partner-season>
          <b-modal
              id="signSpouseWaiver"
              title="Sign your waivers"
              scrollable
              size="xl"
              hide-footer
          >
            <!--            no-close-on-backdrop="true"
                        no-close-on-esc="true"
                        hide-header-close="true"
                      >-->
            <b-card no-body>
              <b-tabs v-model="tabIndex" pills card id="signSpouseWaiverCard">
                <b-tab
                    v-for="(spsWaiver, idx) in spouseWaiverForModal"
                    :key="idx"
                    :title="spsWaiver.waivers_title"
                >
                  <div class="text-center">
                    <div>
                      <i>This waiver is for your membership effective from </i>
                      <b>{{ currentDate }}</b> <i>to </i>
                      <b>{{ spsWaiver.user_info[0].expires_at }}</b>
                    </div>
                    <div>
                      <i>Member name</i>:
                      <b>{{ spsWaiver.user_info[0].member_name }}</b>
                    </div>
                  </div>
                  <p v-html="spsWaiver.waivers_content"></p>
                  <div class="form-inline">
                    <div v-for="(chld, chldId) in spsWaiver.user_info" :key="chldId" class="mb-1">
                      <b-form-checkbox :id="'spouseWaiverCheck-'+(idx+chldId)" @change="markWaiverSign($event,idx+chldId)" value="1" unchecked-value="0" >
                        <b-form-input v-model="waiverData[(idx+chldId)].member_initials" size="sm" maxlength="2" style="width: 40px;" ></b-form-input>
                        I,
                        <span v-if="waiverData[(idx+chldId)].ask_name==1"><b-form-input v-model="waiverData[(idx+chldId)].signed_member" size="sm" maxlength="50"></b-form-input></span>
                        <span v-else>{{ waiverData[(idx+chldId)].signed_member }}</span>
                        , accept the above waiver for {{ waiverData[(idx+chldId)].member_name }} on this date:
                        <b-form-input v-model="waiverCurrentDtTime" size="sm" disabled></b-form-input>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <p class="font-13 mt-4 text-danger"><em>Please check the box and initial in the space provided.</em></p>
                  <span class="text-sm text-danger">{{ waiverErrors.agree }}</span>
                  <span class="text-sm text-danger">{{ waiverErrors.initials }}</span>
                </b-tab>
              </b-tabs>
              <div class="text-right col-sm-12">
                <b-button-group class="mt-2 mb-2">
                  <b-button variant="info" @click="previousWaiver" v-if="tabIndex !== 0">Previous</b-button>
                  <b-button variant="info" @click="spouseWaiverSubmit(0)" v-if="tabIndex == (waiverLength-1)">Sign Now</b-button>
                  <b-button variant="info" @click="nextWaiver" v-else>Next</b-button>
                </b-button-group>
              </div>
            </b-card>
          </b-modal>

          <b-modal
              id="signChildWaiver"
              title="Sign waiver for your children"
              scrollable
              size="xl"
              hide-footer
          >
            <!--            no-close-on-backdrop="true"
                        no-close-on-esc="true"
                        hide-header-close="true"
                      >-->
            <b-card no-body>
              <b-tabs v-model="tabIndex" pills card id="signChildWaiverCard">
                <b-tab
                    v-for="(chldWaiver, idx) in childWaiverForModal"
                    :key="idx"
                    :title="chldWaiver.waivers_title"
                >
                  <div class="text-center">
                    <div>
                      <b>This waiver is for your membership effective from </b>
                      <b>{{ currentDate }}</b> <i>to </i>
                      <b>{{ chldWaiver.user_info[0].expires_at }}</b>
                    </div>
                  </div>
                  <p v-html="chldWaiver.waivers_content"></p>
                  <div class="form-inline">
                    <div v-for="(chld, chldId) in chldWaiver.user_info" :key="chldId" class="mb-3">
                      <b-form-checkbox v-model="waiverData[(idx+chldId)].agree" value="1" unchecked-value="0" >
                        <b-form-input v-model="waiverData[(idx+chldId)].member_initials" size="sm" maxlength="2" style="width: 40px; margin-right: 5px" ></b-form-input>
                        I,
                        <span v-if="waiverData[(idx+chldId)].ask_name==1"><b-form-input v-model="waiverData[(idx+chldId)].signed_member" size="sm" maxlength="50"></b-form-input></span>
                        <span v-else>{{ waiverData[(idx+chldId)].signed_member }}</span>
                        , accept the above waiver for <b class="ml-1 mr-1">{{ waiverData[(idx+chldId)].member_name }}</b> on this date:
                        <b-form-input v-model="waiverCurrentDtTime" size="sm" disabled style="margin-left: 5px;"></b-form-input>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <span class="text-sm text-danger">{{ waiverErrors.agree }}</span>
                  <span class="text-sm text-danger">{{ waiverErrors.initials }}</span>
                </b-tab>
              </b-tabs>
              <div class="text-right col-sm-12">
                <b-button-group class="mt-2 mb-2">
                  <b-button variant="info" @click="childWaiverSubmit()">Sign Now</b-button>
                </b-button-group>
              </div>
            </b-card>
          </b-modal>

          <b-modal
              id="confirm-modal"
              title="Confirmation"
              @hide="resetRenewConfirmation"
          >
            <div class="row">
              <div class="col-md-12" v-if="!renewConfirmation && subdomain != 'shooting' && this.canUpgrade">
                <label>Please choose Renew or Upgrade membership</label>
                <b-form-radio-group
                    label="Please Choose Renewal or Upgrade Membership"
                    id="btn-radios-2"
                    hide-footer
                    v-model="renewData"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    size="md"
                    name="radio-btn-outline"
                    buttons
                ></b-form-radio-group>
                <div class="form-check-label">
                  <ul class="grassrootul" v-if="renewData === 'renew' && renewUpgradeMessages.renew">
                    <li v-for="(message, index) in renewUpgradeMessages.renew" :key="index"><label class="form-check-label" v-html="message"></label></li>
                  </ul>
                  <ul class="grassrootul" v-if="renewData === 'upgrade' && renewUpgradeMessages.upgrade">
                    <li v-for="(message, index) in renewUpgradeMessages.upgrade" :key="index"><label class="form-check-label" v-html="message"></label></li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <div class="form-check">
                  <input type="checkbox" v-model="renewCheck" class="form-check-input" id="renewCheck">
                  <label class="form-check-label" for="renewCheck" v-if="subdomain === 'alliancefastpitch'">Yes, please renew my membership for the {{ renewSeasonYearText }} season.</label>
                  <label class="form-check-label" for="renewCheck" v-else-if="profileData.info.membership.member_type_id == 7 && campskiRenewConfirmation">{{campskiRenewConfirmation.title}}</label>
                  <label class="form-check-label" for="renewCheck" v-else>Yes, please renew my membership for an additional 12 months.</label>
                  <ul class="grassrootul" v-if="profileData.info.membership.member_type_id == 7 && campskiRenewConfirmation">
                    <li  v-for="(message, index) in campskiRenewConfirmation.content" :key="index"><i  class="fas fa-chevron-right"></i>{{ message }}</li>
                  </ul>
                  <ul class="grassrootul" v-else-if="renewConfirmationMessages">
                    <li  v-for="(message, index) in renewConfirmationMessages" :key="index"><i  class="fas fa-chevron-right"></i>{{ message }}</li>
                  </ul>
                  <span id="confirmation_alert"></span>
                </div>
              </div>
            </div>
            <template #modal-footer="{ ok, cancel}">
              <b-button variant="secondary" @click="cancel()">
                Cancel
              </b-button>
              <b-button variant="primary"  @click="handleRenewConfirmation">
                Continue
              </b-button>
            </template>
          </b-modal>

          <b-modal id="upgrade-confirm" title="Confirmation" @hide="resetRenewConfirmation" v-if="canUpgrade && !membershipExpiring">
            <div class="row">
              <div class="form-check">
                <input type="checkbox" v-model="upgradeCheck" class="form-check-input" id="upgradeCheck">
                <label class="form-check-label" for="upgradeCheck">Yes, please upgrade my membership.</label>
              </div>
              <ul class="grassrootul" v-if="renewUpgradeMessages.upgrade">
                <li v-for="(message, index) in renewUpgradeMessages.upgrade" :key="index"><label class="form-check-label" v-html="message"></label></li>
              </ul>
              <span id="upgrade_confirmation_alert"></span>
            </div>
            <template #modal-footer="{ ok, cancel}">
              <b-button variant="secondary" @click="cancel()">
                Cancel
              </b-button>
              <b-button variant="primary"  @click="handleUpgradeConfirmation">
                Continue
              </b-button>
            </template>
          </b-modal>

          <b-modal
              id="viewOfficialRatingsDetails"
              title="Renewal Requirements"
              scrollable
              size="xl"
              hide-footer
          >
            <div class="row">
              <div class="col-md-12 text-center mb-3" v-if="selectedOfficialRating">
                <button class="btn btn-primary mr-1">Division <span class="badge badge-light">{{ selectedOfficialRating.Rating.split(": ")[2] }}</span></button>
                <button class="btn btn-primary mr-1">Rating <span class="badge badge-light">{{ selectedOfficialRating.Rating.split(": ")[0] }}</span></button>
                <button class="btn btn-primary mr-1">Event <span class="badge badge-light">{{ eventName[selectedOfficialRating.EventAbbreviation] }}</span></button>
                <button class="btn btn-primary">Level <span class="badge badge-light">{{ selectedOfficialRating.Rating.split(": ")[1] }}</span></button>
              </div>
              <div class="col-md-12">
                <div class="text-center" v-if="viewRatingLoader"><i class="fa fa-circle-notch fa-lg fa-spin"></i></div>
                <table class="table" style="border: 1px solid;border-radius: 10px;" v-if="detailsData">
                  <thead style="background:black;color:white">
                  <th>Category</th>
                  <th>Met</th>
                  <th>Required</th>
                  <th>Fulfilled</th>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].CategoryName }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].Met }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].Required }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].Fulfilled }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].CategoryName }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].Met }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].Required }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].Fulfilled }}</b>
                    </td>
                  </tr>
                  <tr v-for="details in detailsData.EventlistpersonId" :key="details.Event_ID">
                    <td >{{ usdate(details.ParticpationDate) }}</td>
                    <td colspan="3">{{ details.SanctionedEventName }}</td>
                  </tr>
                  <tr>
                    <td><b>Certifications</b></td>
                    <td colspan="3"><b>{{ detailsData.CertificationInfopersonid[0].RequirementsStatusMessage }}</b></td>
                  </tr>
                  <tr>
                    <td><p>Membership</p></td>
                    <td colspan="3"><p><b>No</b> as of: {{ splitter(detailsData.MemberInfopersonid[0].MembershipStatusDetails) }}</p></td>
                  </tr>
                  <tr>
                    <td colspan="4"><p>{{ detailsData.MemberInfopersonid[0].MembershipStatusDetails }}</p></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
      <!-- <div class="footer-inner">
          <div class="row footer-row">
              <div class="col-md-7 pl-3">
                  <div class="footeremail col-md-12 mb-1 pl-0" v-if="prop_footer.phone || prop_footer.email">
                      <div class="">
                          <div class=" ">
                              <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                          </div>
                      </div>
                  </div>
              <p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p>
              </div>
              <div class="col-md-5 pr-4">
                  <p class="text-right mr-2">Powered by<img class="ml-1" v-bind:src="'/dist/img/memberregistration/int-sports.png'" alt="footer-img" /></p>
              </div>
          </div>
      </div> -->
    </div>
    <div class="pb-4"></div>

    <dashboardfooter></dashboardfooter>
  </div>
</template>

<script>
import axios from "axios";
import AutoRenewalButton from "../../admin/components/AutoRenewalButton";
// import sidebar from "./components/Sidebar";
//import dashboardfooter from "./components/Footer";
import CustomAlert from "../../CustomAlert";
import * as $ from "jquery";
import moment from "moment";
import { EventBus } from '@/event-bus';
import PartnerSeason from "../../Member/Dashboard/PartnerSeason";
export default {
  name: "memberDashboard",
  components: { CustomAlert,AutoRenewalButton,PartnerSeason },
  props: ['partners'],
  data() {
    return {
      renewSeasonYearText: '',
      displayMissingMemFields: false,
      prop_dashboardLinks: null,
      dashboard_header: {},
      renewalUpgradeText:'Renew/Upgrade Membership',
      canUpgrade:true,
      membershipExpiring:true,
      getError:false,
      prop_footer: {},
      renewData: "renew",
      digitalInfoView:true,
      autoRenewalModalOpen: false,
      autoRenew:0,
      page:'dashboard',
      renewCheck: false,
      upgradeCheck: false,
      renewConfirmation: false,
      options: [
        { text: "Renew membership", value: "renew" },
        { text: "Upgrade Membership", value: "upgrade" },
      ],
      profileData: {
        info: [],
      },
      autoRenewData:{
        regId:"",
        status:"",
      },
      cardError: {},
      tblWaiverFields: [
        { key: "title", sortable: true, label: "Waiver Name" },
        { key: "pivot.initials", sortable: false, label: "Initials" },
        { key: "pivot.signed_for", sortable: true, label: "Signed For" },
        { key: "pivot.signed_user", sortable: true, label: "Signed By" },
        { key: "pivot.signed_at", sortable: false, label: "Signed At", formatter: "usdate" },
      ],
      spouseWaiverForModal: [],
      childWaiverForModal: [],
      tabIndex: 0,
      waiverLength: 0,
      waiverData: [],
      waiverErrors: {
        agree: "",
        initials: "",
      },
      waiverCurrentDtTime: "",
      ratingData:[],
      personData:[],
      ratingTblFields:[
        { key: 'division', sortable: true, label: 'Division' },
        { key: 'rating_type', sortable: true, label: 'Rating' },
        { key: 'level', sortable: true, label: 'Level' },
        { key: 'EventAbbreviation', sortable: true, label: 'Event' },
        { key: 'Effective_Date', sortable: true, label: 'Effective Date', formatter: "usdate" },
        { key: 'ExpirationDate', sortable: true, label: 'Expiration Date', formatter: "chkDateExp" },
        { key: "Active", sortable: false, label: 'Eligible to renew' },
        { key: "details", sortable: false, label: 'Details' },
      ],
      tblRatingDetailsFields: [
        { key: "ParticpationDate", sortable: true, label: 'Date', formatter: (value) => moment(value).format("MM/DD/YYYY") },
        { key: "SanctionedEventName", sortable: true, label: 'Event' }
      ],
      ratingSortBy:'expiration_date',
      ratingSortDesc:'false',
      certsData:[],
      certsTblFields:[
        { key: 'name', sortable: true, label: 'Certification' },
        { key: 'data', sortable: true, label: 'From - To' },
      ],
      certsSortBy:"EffectiveToDate",
      certsSortDesc:"false",
      eventName:{
        '':'None',
        'N':'None',
        'S':'Slalom',
        'T':'Trick',
        'J':'Jump'
      },
      currentDate: '',
      showLoader:true,
      safeSportApiCall: false,
      safeSportRefreshButton: '',
      isCurrentyear:"",
      downloading: {
        ratings: false,
        certs: false,
        events: false
      },
      eventsData: [],
      eventsTblFields: [
        { key: 'SanctionedEventName', sortable: true, label: 'Event Name', formatter: (value) => value || 'NA' },
        { key: 'SanctionedEventSanctionNumber', sortable: true, label: 'Sanction Number', formatter: (value) => value || 'NA' },
        { key: 'SanctionedEventClass', sortable: true, label: 'Event Class', formatter: (value) => value || 'NA' },
        { key: 'DivisionCode', sortable: true, label: 'Division', formatter: (value) => value || 'NA' },
        { key: 'RatingType', sortable: true, label: 'Rating', formatter: (value) => value || 'NA' },
        { key: 'ParticpationDate', sortable: true, label: 'Particpation Date', formatter: (value) => value || 'NA' }
      ],
      eventsPerPage: 5,
      eventsCurrentPage: 1,
      selectedOfficialRating: "",
      dashboardWelcomeContent:[],
      dashboardBannerContent:[],
      dashboardBannerStatus:false,
      dashboardWelcomeStatus:false,
      linkToPage: '',
      viewRatingLoader: false,
      detailsData: null
    };
  },
  methods: {
    splitter(value){
      let val = value.split('of');
      return val[1];
    },
    markWaiverSign(checked, index){
      this.waiverData[index].agree = checked;
    },
    renewMemberShip: function() {
      if(this.membershipExpiring){
        this.$bvModal.show("confirm-modal");
      }else{
        this.renewData = "upgrade";
        this.$bvModal.show("upgrade-confirm");
      }
    },
    handleRenewConfirmation: function() {
      if(this.renewData == 'renew') {
        this.renewConfirmation = true;
        if(this.renewCheck){
          if(this.subdomain === 'alliancefastpitch') {
            window.open("/member/renewal", "_self");
          } else {
            window.open("/member/" + this.renewData, "_self");
          }
        }else{
          $('#confirmation_alert').html("<p style='color:red;font-size:13px'>Please check the box</p>");
        }
      }
      if (this.renewData == "upgrade") {
        window.open("/member/" + this.renewData, "_self");
      }
    },
    handleUpgradeConfirmation(){
      if(this.upgradeCheck){
        window.open("/member/" + this.renewData, "_self");
      } else {
        $('#upgrade_confirmation_alert').html("<p style='color:red;font-size:13px'>Please check the box</p>");
      }
    },
    resetRenewConfirmation:function()
    {
      this.renewData = 'renew';
      this.renewConfirmation = false;
      this.renewCheck = false;
      this.upgradeCheck = false;
    },
    loadMembersInfo() {
      axios
          .get(this.basePath + "api/member/my_profile", {
            headers: this.memberHeaders,
          })
          .then((response) => {
            if(!this.getSiteProps('general.member_profile_addition_info') && this.getSiteProps('general.member_profile_addition_info') != undefined){
              this.digitalInfoView = false;
            }
            this.profileData.info = response.data.data;
            this.autoRenew = response.data.data.auto_renew;
            this.autoRenewData.regId = response.data.data.registration[0].id;
            this.autoRenewData.status = response.data.data.auto_renew;
            if(this.subdomain == "shooting")
            {
              this.page = "Shooting Dashboard";
            }
            EventBus.$emit('getGroupCommittee', response.data.data.groupCommittee);
            this.membershipExpiring = this.profileData.info.membership_expiring;
            this.canUpgrade = this.profileData.info.canUpgrade;
            this.renewalUpgradeText = (!this.membershipExpiring)? 'Upgrade Membership':'Renew/Upgrade Membership';
            this.renewalUpgradeText = (this.canUpgrade)? this.renewalUpgradeText:'Renew Membership';
            this.renewalUpgradeText = (this.subdomain=='waterski'&&this.profileData.info.membership.id == 13)? 'Upgrade Membership' :this.renewalUpgradeText;
            if(!this.canUpgrade){
              this.renewConfirmation = true;
            }
            if (this.profileData.info.waiver_status === 0
                && response.data.data.signWaiver && this.subdomain != 'alliancefastpitch') {
              axios.get(
                  this.basePath + "api/member/get_spouse_waiver/" + this.profileData.info.registration[0]['id'],
                  { headers: this.memberHeaders }
              ).then((resp) => {
                this.spouseWaiverForModal = resp.data.data;
                let list = [];
                let waiverUser = [];
                let i=0;
                $.each(this.spouseWaiverForModal, function(idx, value) {
                  let waiverId = value.waivers_id;
                  $.each(value.user_info, function(key, info){
                    list = info;
                    list.agree = 0;
                    list.waivers_id = waiverId;
                    waiverUser[i++] = list;
                  });
                });
                this.waiverData = waiverUser;
                this.waiverLength = this.spouseWaiverForModal.length;
              }).catch((err) => {
                console.log("Spouse waiver error");
              });
              if (!this.profileData.info.waiver_status) {
                this.$nextTick(() => { this.$bvModal.show("signSpouseWaiver"); });
              }
            }
            if(this.profileData.info.id!='' && this.profileData?.info?.membership?.member_type_id !== 7 && this.subdomain === "waterski"){
              axios.get(this.basePath + 'api/member/officialratings/'+this.profileData.info.id, {headers: this.memberHeaders})
                  .then(function (response) {
                    if(response.data.status!='error') {
                      const resp = response.data.data;
                      if(response.data.message!="Unauthorized parameter UserId from this token"){
                        this.ratingData = resp.rating;
                        this.certsData = resp.certs;
                        this.personData = resp.personInfo;
                        this.eventsData = resp.events;
                      }
                      else{
                        this.getError = true;
                      }
                    }else{
                      this.personData.Email = response.data.message;
                    }
                    this.showLoader=false;
                  }.bind(this))
                  .catch(function (error) {
                    console.log(error);
                  });
            }

            this.autoRefreshSafeSport(); // Auto Refresh safe sport

            if (this.profileData.info.need_child_waiver_sign) {
              axios.get(
                  this.basePath + "api/member/get_children_waiver/" + this.profileData.info.registration[0]['id'],
                  { headers: this.memberHeaders }
              ).then((resp) => {
                this.childWaiverForModal = resp.data.data;
                let list = [];
                let waiverUser = [];
                let i=0;
                $.each(this.childWaiverForModal, function(idx, value) {
                  let waiverId = value.waivers_id;
                  $.each(value.user_info, function(key, info){
                    list = info;
                    list.agree = 0;
                    list.waivers_id = waiverId;
                    waiverUser[i++] = list;
                  });
                });
                this.waiverData = waiverUser;
                this.waiverLength = this.childWaiverForModal.length;
              }).catch((err) => {
                console.log("Child waiver error");
              });
              this.$nextTick(() => { this.$bvModal.show("signChildWaiver"); });
            }
            if(this.subdomain == "shooting"&&this.profileData.info.membership.member_type_id !=7){
              if(this.profileData.info.personal.cmp_no == null && this.profileData.info.personal.nra_no == null &&
                  this.profileData.info.personal.sasp_no == null && this.profileData.info.personal.sctp_no == null){
                this.displayMissingMemFields = true;
                this.alertMessage = "Please fill out the CMP, NRA, SASP, SCTP Membership ID's";
              }
            }
            this.renewSeasonYearText = this.profileData.info.renew_season_year_text;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    autoRefreshSafeSport(){
      if(
          this.profileData.info.safe_sport != null &&
          (
              [1,13].indexOf(this.profileData.info.safe_sport.safe_sport_status) !== -1 ||
              (
                  this.profileData.info.safe_sport.safe_sport_status === 3 &&
                  moment(this.profileData.info.safe_sport.expiration, "MM/DD/YYYY").isBefore(new Date())
              )
          ) &&
          this.safeSportApiCall === false
      ) {
        this.getSafeSportUserApi();
      } else {
        this.safeSportApiCall = true;
      }
    },
    getSafeSportUserApi() {
      this.safeSportRefreshButton = 'Processing...';
      axios.get(this.basePath+"api/member/safeSport",{ headers:this.memberHeaders })
          .then((res) => {
            this.safeSportApiCall = true;
            this.loadMembersInfo();
            return res;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            this.safeSportRefreshButton = 'Refresh';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    getWaiverName(id) {
      for (const waiver in this.profileData.info.registration.waivers) {
        if (this.profileData.info.registration.waivers[waiver].id == id) {
          return this.profileData.info.registration.waivers[waiver].title;
        }
      }
    },
    nextWaiver() {
      if (this.validateWaiverSign(0)) {
        this.tabIndex++;
        document.getElementById("signSpouseWaiverCard").scrollIntoView();
      }
    },
    previousWaiver() {
      this.tabIndex--;
      this.waiverErrors.agree = "";
      this.waiverErrors.initials = "";
      document.getElementById("signSpouseWaiverCard").scrollIntoView();
    },
    spouseWaiverSubmit(checkAll=0) {
      this.waiverErrors.agree = "";
      this.waiverErrors.initials = "";
      if (this.validateWaiverSign(checkAll)) {
        axios
            .post(
                this.basePath +
                "api/member/update_spouse_waiver/" +
                this.profileData.info.registration[0]['id'],
                this.waiverData,
                { headers: this.memberHeaders }
            )
            .then((response) => {
              if (response.data.status == "success") {
                this.$bvModal.hide("signSpouseWaiver");
                this.alertMessage = "Waiver signed successfully";
                this.displayAlert = true;
              }
              this.loadMembersInfo();
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },
    childWaiverSubmit(){
      this.waiverErrors.agree = "";
      this.waiverErrors.initials = "";
      if (this.validateWaiverSign(1)) {
        axios
            .post(
                this.basePath +
                "api/member/update_child_waiver/" +
                this.profileData.info.registration[0]['id'],
                this.waiverData,
                { headers: this.memberHeaders }
            )
            .then((response) => {
              if (response.data.status == "success") {
                this.$bvModal.hide("signChildWaiver");
                this.alertMessage = "Waiver signed successfully";
                this.displayAlert = true;
              }
              this.loadMembersInfo();
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },
    validateWaiverSign(checkAll) {
      if(checkAll===1){
        let valid='';
        let agreeTotal=0;
        let initTotal=0;
        $.each(this.waiverData, function(idx, value) {
          let agree = (value.agree == 1);
          let initials = (value.member_initials != "");
          agreeTotal = agreeTotal+(agree?0:1);
          initTotal = initTotal+(initials?0:1);
          valid+=(agree && initials ? 1 : 0);
        });
        this.waiverErrors.agree = (agreeTotal>0 ? "Please acknowledge by checking the box":"");
        this.waiverErrors.initials = (initTotal>0 ? "You must enter two letters to initial":"");
        console.log(valid);
        return (valid.indexOf('0')==-1);
      }else {
        let agree = $('#spouseWaiverCheck-' + this.tabIndex).is(":checked");
        let initials = (this.waiverData[this.tabIndex].member_initials != "");
        this.waiverErrors.agree = (agree ? "" : "Please acknowledge by checking the box");
        this.waiverErrors.initials = (initials ? "" : "You must enter two letters to initial");
        return agree && initials;
      }
    },
    chkDateExp(value){
      let expDt = moment(value);
      if(value == null) {
        return "N/A";
      }else if(moment().isAfter(expDt)){
        return '<span class="text-danger">'+expDt.format("MM/DD/YYYY")+'</span>';
      }else{
        return expDt.format("MM/DD/YYYY");
      }
    },
    downloadOfficials(type, personId){
      this.downloading[type] = true;
      let url = this.basePath + 'api/official/'+type+'/'+personId;
      axios.get(url, {headers:this.memberHeaders, responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = personId+"_"+type+'.pdf';
            link.click();
            link.remove();
            this.downloading[type] = false;
          }).catch((error)=>{
        console.log("error download");
        this.downloading[type] = false;
      });
    },
    viewOfficialDetails(item){
      this.selectedOfficialRating = item;
      this.$bvModal.show("viewOfficialRatingsDetails");
    },
    formatDateTime(value){
      return moment(value).format("MM/DD/YYYY");
    },
    memberWelcomeContents() {
      axios.get(this.basePath+'api/cmsSection/'+1)
          .then(function (response) {
            response.data.data.forEach(content => {
              if (content.section_id == 1) {
                this.dashboardWelcomeContent = content;
                this.dashboardWelcomeStatus = true;
              } else if (content.section_id == 6) {
                this.dashboardBannerContent = content;
                this.linkToPage = content.link;
                this.dashboardBannerStatus = true;
              }
            });
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    getCurrentDate() {
      const today = moment().format('MM/DD/YYYY');
      this.currentDate = today;
    },
    handlePartnerClicked(id) {
      this.$router.push({ name: "partners", params: { id: id } });
    },
    officialRatingView(){
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        if (modalId === "viewOfficialRatingsDetails") {
          this.detailsData = null;
          this.viewRatingLoader=true;
          axios.get(
              this.basePath + 'api/roster/renewal_requirements/'+this.selectedOfficialRating?.PersonID+'/'
              +this.selectedOfficialRating?.Event_ID+'/'+this.selectedOfficialRating?.RatingType_ID
          )
          .then(function (response) {
            if(response.data.status !== 'error') {
              this.detailsData = response.data.data;
            }else{
              this.detailsData = response.data.message;
            }
            this.viewRatingLoader=false;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
        }
      })
    }
  },
  updated(){
    this.prop_dashboardLinks = this.getSiteProps('memberdashboard.links');
    this.prop_footer = this.getSiteProps('memberRegistration.footer');
    this.dashboard_header = this.getSiteProps('memberdashboardbanner');
  },
  mounted() {
    this.loadMembersInfo();
    this.memberWelcomeContents();
    this.getCurrentDate();
    this.waiverCurrentDtTime = new Date().toLocaleString();
    this.isCurrentyear = new Date();
    this.officialRatingView();
  },
  computed: {
    renewUpgradeMessages(){
      return this.getSiteProps('memberdashboard.renewUpgrade.message');
    },
    enableMagazine(){
      return this.profileData?.info?.membership?.id == 7 || this.profileData?.info?.membership?.id == 13 || (this.profileData?.info?.membership?.id == 5 && this.profileData?.info?.personal_details?.relation != 0);
    },
    renewConfirmationMessages(){
      return this.getSiteProps('renewConfirmation.member');
    },
    campskiRenewConfirmation(){
      return this.getSiteProps('campskiRenewConfirmation');
    },
  }
};
</script>
<style>
.page-link{
  color: #000 !important;
}
.page-item.active .page-link{
  background-color: #002c59!important;
  border-color: #002c59!important;
  color: #fff !important;
}
.card-img{
  height: 200px !important;
  object-fit: none;
}
</style>